/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Bio = () => {

  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          motto
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const motto = data.site.siteMetadata?.motto;

  return (
    <footer>
      <div className="bio">
        © {new Date().getFullYear()} Rovine Media - {motto}
      </div>
    </footer>
  );
};

export default Bio;
