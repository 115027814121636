import React from 'react';
import { Link } from 'gatsby';
import GlobalMenu from './global-menu';


const GlobalHeader = ({ title }) => {
	return (
		<div className="global-header">
			<h1>
				<Link to="/">{title}</Link>
			</h1>
			<GlobalMenu />
		</div>

	);
};


export default GlobalHeader;