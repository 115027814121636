import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FaBars } from 'react-icons/fa';

const options = {
	Home: '/',
	'News App': '/right-news/share/',
	About: '/mission-statement/',
};

const entries = Object.entries(options).map(
	([title, link]) => ({ title, link })
);


const GlobalMenu = () => {
	const [ expanded, setExpanded ] = useState(false);
	const onToggle = () => setExpanded(!expanded);
	const onHide = () => setExpanded(false);

	const optionsClass = `menu-options ${expanded ? '': 'hidden'}`;

	return (
		<div className="global-menu">
			<div className="menu-button">
				<FaBars onClick={onToggle} />
			</div>
			<div className={optionsClass}>
				{entries.map(
					e => <Link key={e.link} to={e.link} onClick={onHide}>{e.title}</Link>
				)}
			</div>
		</div>
	);
};

export default GlobalMenu;